// import background from '../public/images/kuliah.jpg';
import React, { useState } from 'react';
import './App.css';
import { InstagramEmbed } from 'react-social-media-embed';

function App() {
  const [listLearn] = useState([
    {
      title: 'Data Science / Engineering / Analyst',
      desc: 'DQLab adalah platform belajar online yang berfokus pada pengenalan Data Science & Artificial Intelligence (AI) dengan menggunakan bahasa pemrograman populer seperti Python dan SQL, serta platform edukasi pertama yang mengintegrasi fitur ChatGPT & mengutamakan pembelajaran praktik langsung yang dapat diterapkan di dunia nyata.',
      link: 'https://academy.dqlab.id/subscribe/payment/PRDDQLAB6M?dqlabref=timaffiliated&affCode=isalzufari&voucherCode=ISAL02PR6',
      mitra: 'DqLab',
    },
    {
      title: 'Front-end / Machine Learning',
      desc: 'DBS Foundation Coding Camp adalah sebuah program pelatihan coding secara online untuk meningkatkan kompetensi masyarakat Indonesia dalam bidang teknologi informasi.',
      link: 'https://www.dicoding.com/programs/dbscodingcamp/registration?referrer_id=826802',
      mitra: 'DBS Foundation',
    },
    {
      title: 'Belajar Bareng',
      desc: 'Belajar Bareng soal System Design, Database, Back-End, Clean Architecture, Unit Testing',
      link: 'https://forms.gle/SxMbr7hShRkRXLHHA',
      mitra: 'HaloTSM'
    }
  ]);

  return (
    <>
      <div class="container-md my-5">

        <div class="position-relative p-5 text-center text-muted bg-body border border-dashed rounded-5 shadow">
          <button type="button" class="position-absolute top-0 end-0 p-3 m-3 btn-close bg-secondary bg-opacity-10 rounded-pill" aria-label="Close"></button>
          <div class="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3">
            <div class="col-lg-7 pt-lg-3">
              <h1 class="display-4 fw-bold lh-1 text-body-emphasis">Faishal Zufari</h1>
              <span class="text-body-secondary font-monospace">SWE, Content Creator, Design Thinker, and Story Teller</span>
              <figure>
                <blockquote class="blockquote">
                </blockquote>
                <figcaption class="blockquote-footer">
                  <p class='lead'><span className='fst-italic'>In the middle of process</span>, seorang gen z yang suka explorasi sana sini, suka ngoprek, dan senang untuk berbagi cerita.</p>
                </figcaption>
              </figure>
            </div>
            <div class="col-lg-4 offset-lg-1 p-0 overflow-hidden">
              <figure>
                <div className="card">
                  <blockquote class="blockquote">
                    <img class="rounded img-fluid" src="./images/isalzufari.jpg" alt="Faishal Zufari" />
                  </blockquote>
                  <figcaption class="blockquote-footer">
                    <cite title="Source Title">paso corto, vista larga</cite>
                  </figcaption>
                </div>
              </figure>
              <ul class="justify-content-end list-unstyled d-flex">
                <li class="ms-3"><a class="text-body-secondary" href="https://www.instagram.com/isalzufari/" target='_blank' without rel="noreferrer"><i class="bi bi-instagram" style={{ fontSize: '1.5rem' }}></i></a></li>
                <li class="ms-3"><a class="text-body-secondary" href="https://www.tiktok.com/@isalzufari/" target='_blank' without rel="noreferrer"><i class="bi bi-tiktok" style={{ fontSize: '1.5rem' }}></i></a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="position-relative text-center text-muted bg-body border border-dashed rounded-5 shadow mt-3">

          <div class="px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom">daftar belajar</h2>
            <p className='lead'>belajar dari platform ini</p>

            <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3">

              {listLearn.map((e, key) => (
                <div key={key} className="col">
                  <div className="card h-100 rounded-4">
                    <div className="card-body">
                      <h5 class="card-title">{e.title}</h5>
                      <p class="card-text">{e.desc}</p>
                    </div>
                    <div class="card-footer bg-transparent">
                      <a href={e.link} target='_blank' rel="noreferrer" className='text-decoration-none'>{`Belajar di ${e.mitra}`}</a>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>

        </div>

        <div class="position-relative text-center text-muted bg-body border border-dashed rounded-5 shadow mt-3">

          <div class="px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom">hari - hari</h2>
            <p className='lead'><span className='fst-italic'>learn to love the process, </span>percaya pada setiap langkah nya</p>

            <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3">
              <div class="col">
                <div class="h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style={{ backgroundImage: "url('./images/kuliah.jpg')", backgroundPosition: 'center', backgroundSize: 'cover' }}>
                  <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                    <h3 class="pt-5 mt-5 mb-4 lh-1 fw-bold">kuliah</h3>
                    <ul class="d-flex list-unstyled mt-auto">
                      <li class="d-flex align-items-center me-3">
                        <small>Information System</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style={{ backgroundImage: "url('./images/kerja.jpg')", backgroundPosition: 'center', backgroundSize: 'cover' }}>
                  <div class="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                    <h3 class="pt-5 mt-5 mb-4 lh-1 fw-bold">kerja</h3>
                    <li class="d-flex align-items-center me-3">
                      <small>Software Engineer</small>
                    </li>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg" style={{ backgroundImage: "url('./images/mentoring.jpg')", backgroundPosition: 'center', backgroundSize: 'cover' }}>
                  <div class="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                    <h3 class="pt-5 mt-5 mb-4 lh-1 fw-bold">ngonten</h3>
                    <li class="d-flex align-items-center me-3">
                      <small>Tech</small>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="position-relative text-center text-muted bg-body border border-dashed rounded-5 shadow mt-3">
          <div class="px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom">kadang sharing</h2>
            <p className='lead'><span className='fst-italic'>giving back to the community</span> dalam misinya untuk memberikan insight dari pengalaman pribadi</p>

            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-stretch g-4 py-3">

              <div class="col">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url="https://www.instagram.com/p/C2uU9TcP587" width={600} />
                </div>
              </div>

              <div class="col">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url="https://www.instagram.com/p/Cz8OaCtSwOv" width={600} />
                </div>
              </div>

              <div class="col">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url="https://www.instagram.com/p/CsTvztBBY3p" width={600} />
                </div>
              </div>

              <div className="col">
                <div className="card">
                  <img className='img-fluid' src="./images/sharing/self_awareness_for_content_creator.jpeg" alt="Self Awareness For Content Creator" />
                  <div className="card-body">
                    <a href='https://youtu.be/NHQbqX9fDU0?si=gjm5OJ9fGp5lsOwQ&t=620' target='_blank' rel="noreferrer" className='text-decoration-none'>Lihat di Youtube</a>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="card">
                  <img className='img-fluid' src="./images/sharing/self_confidence_is_about_process.jpg" alt="Self Confidence is about Process" />
                  <div className="card-body">
                    <a href='https://youtu.be/bESAjbhKXaQ?si=3_FMNdNR--16bLTB&t=295' target='_blank' rel="noreferrer" className='text-decoration-none'>Lihat di Youtube</a>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="position-relative text-center text-muted bg-body border border-dashed rounded-5 shadow mt-3">
          <div class="px-4 py-5" id="custom-cards">
            <h2 class="pb-2 border-bottom">kadang mentoring</h2>
            <p className='lead'><span className='fst-italic'>in the middle of process</span> dalam misinya untuk membantu teman² menuju proses nya</p>

            <div class="row row-cols-1 row-cols-lg-2 align-items-stretch g-4 py-3">

              <div class="col">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url="https://www.instagram.com/p/C1oyaYKLk_V" width={600} />
                </div>
              </div>

              <div class="col">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url="https://www.instagram.com/p/CrzdLVlpmf0" width={600} />
                </div>
              </div>

              <div className="col">
                <div class="border px-4 pt-5 text-center border-bottom">
                  <h1 class="display-4 fw-bold text-body-emphasis">Back-End</h1>

                  <p class="lead">membantu teman-teman menyelesaikan kelas <span className='fst-italic'>Back-End Developer Fundamental - Expert</span> dari Dicoding x DBS Foundation</p>

                  <div class="overflow-hidden">
                    <div class="container">
                      <img src="./images/mentoring_be_expert.png" class="img-fluid border rounded-3 shadow mb-4" alt="Mentoring Back-End Expert" width="700" height="500" loading="lazy" />
                    </div>
                  </div>

                  <div className='border mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:activity:7104849514695430144" height="445" width="504" frameborder="1" allowfullscreen="" title="Embedded post"></iframe>
                  </div>

                  <div className='border mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:activity:7140575861832884224" height="445" width="504" frameborder="1" allowfullscreen="" title="Embedded post"></iframe>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="position-relative p-5 text-center text-muted bg-body border border-dashed rounded-5 shadow mt-3">

          <h2 class="pb-2 border-bottom">terbuka untuk peluang baru!</h2>
          <p className='lead'><span className='fst-italic'>love to share</span> soal technology, self development, dan content creation</p>
          <div class="row row-cols-1 row-cols-lg-2 align-items-stretch g-4 py-3">

            <div className="col d-flex justify-content-center align-self-center">
              <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="light" data-type="VERTICAL" data-vanity="isalzufari" data-version="v1"></div>
            </div>

            <div className="col">
              <div class="bg-body-tertiary shadow-sm rounded me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
                <div class="my-3 p-3">
                  <p class="lead">kontak via telegram @<a href='https://t.me/isalzufari' target='_blank' rel='noreferrer'>isalzufari</a> atau scan qr code dibawah untuk urusan duniawi</p>
                </div>
                <div class="bg-body shadow-sm mx-auto" style={{ width: '80%', height: '300px', borderRadius: '21px 21px 0 0', overflow: 'hidden' }}>
                  <img class="img fluid" style={{ height: 300 }} src="./images/qr_code_isalzufari_telegram.jpg" alt="Faishal Zufari" />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default App;
